import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import FeedMainContent from "./FeedMainContent";
import FeedMainTag from "./FeedMainTag";
import FeedMainText from "./FeedMainText";
import styled from "styled-components";

import Lcampaign from "assets/imgs/icons/icon_socialCampaign.svg";
import Lchannel from "assets/imgs/icons/icon_socialChannel.svg";
import Lcommunity from "assets/imgs/icons/icon_socialCommu.svg";
import Ltheme from "assets/imgs/icons/icon_socialTheme.svg";

export default function FeedMain({ feed, page }) {
  const { jeboMediaList: jeboMedia, social } = feed;

  const history = useHistory();
  const match = useRouteMatch();
  const location = useLocation();

  const gotoSocial = (scType) => {
    if (social.scType === 1) {
      history.push({
        pathname: "/social/theme",
      });
      // 테마
    } else if (social.scType === 2) {
      // 캠페인
      history.push({
        pathname: "/social/campaign",
      });
    } else if (social.scType === 3) {
      // 채널
      history.push({
        pathname: "/social/channel",
      });
    } else if (social.scType === 4) {
      // 커뮤니티
      history.push({
        pathname: "/social/community",
      });
    }
  };

  return (
    <div className="item-body-wrap">
      <div className="item-body">
        {social && (
          <SocialLabel
            scType={social.scType && social.scType}
            onClick={(e) => {
              gotoSocial(social.scType);
            }}
          >
            {social.scType === 1
              ? "테마"
              : social.scType === 2
              ? "캠페인"
              : social.scType === 3
              ? "채널"
              : "커뮤 니티"}
          </SocialLabel>
        )}
        {feed.feedType === 0 || feed.feedType === 2 ? (
          <>
            <FeedMainContent
              feed={feed}
              page={page}
              scType={social && social.scType}
            />
            {feed.mediaCnt > 0 && (
              <div
                className="item-info"
                // style={
                //   page === "member" || page === "mypage"
                //     ? { height: "auto" }
                //     : null
                // }
                // 해당 코드를 넣었을 때 피드 아이템의 높이가 설정값 이상으로 늘어나서 타 ui 영역을 침범하여 주석처리
                onClick={(e) => {
                  e.stopPropagation();

                  history.push({
                    pathname: `${match.url}/detail/${feed.jeboId}`,
                    search: location.search,
                  });
                }}
              >
                {feed && feed.hashTag && <FeedMainTag feed={feed} />}
                <FeedMainText feed={feed} page={page} grid={false} />
              </div>
            )}
          </>
        ) : (
          <>
            <div className="item-photo share">
              <div
                className="share__textContainer"
                onClick={(e) => {
                  e.stopPropagation();
                  history.push({
                    pathname: `${match.url}/detail/${feed.jeboId}`,
                    search: location.search,
                  });
                }}
              >
                <span className="share__title">#제보요청</span>
                <div className="share__textBox">
                  <div className="share__desc">
                    {/* {feed && feed.hashTag && <FeedMainTag feed={feed} />} */}
                    <FeedMainText feed={feed} page={page} grid={false} />
                  </div>
                </div>
              </div>
              <div></div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

const SocialLabel = styled.div`
  cursor: pointer;
  position: absolute;
  top: -1px;
  right: 15px;
  z-index: 1;
  width: 45px;
  height: 45px;

  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  text-align: center;
  padding: 0 6px 10px;
  line-height: 13px;
  font-weight: 500;
  letter-spacing: -0.5px;

  background: url(${({ scType }) =>
      scType === 1
        ? Ltheme
        : scType === 2
        ? Lcampaign
        : scType === 3
        ? Lchannel
        : scType === 4
        ? Lcommunity
        : ""})
    no-repeat;
  background-size: contain;
`;
