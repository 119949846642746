import jeboApi from "api/api";
import default_event from "assets/imgs/default_event.png";
import user_icon from "assets/imgs/icons/user_icon.svg";
import { signAppContext } from "components/providers/SignContextProvider";
import { signLayerContext } from "components/providers/SignLayerContextProvider";
import { useContext, useEffect, useReducer, useState } from "react";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import SwiperCore, { Autoplay, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import BannerMapContainer from "./BannerMapContainer";
import styled from "styled-components";
import ContentItemDefault from "../social/ContentItemDefault";

SwiperCore.use([Navigation, Pagination, Autoplay]);
export default function BannerSection() {
  const [rankers, setRankers] = useState([]);
  const [jeboEventList, setJeboEventList] = useState([]);
  const { myInfo, isLoggedIn, handleSignOutApp } = useContext(signAppContext);
  const { signLayer, setSignLayer } = useContext(signLayerContext);

  const history = useHistory();
  const match = useRouteMatch();

  useEffect(() => {
    getTopJeboMemberList();

    //캠페인 불러오기
    getJeboEventList();
  }, []);

  // const getJeboEventList = async () => {
  //   try {
  //     const { status, data } = await jeboApi.getJeboEventList();

  //     if (status === 200) {
  //       const jeboEventList = JSON.parse(data.jeboEventList);

  //       setJeboEventList(jeboEventList);
  //     } else if (status === 204) {
  //       setJeboEventList(null);
  //       console.log("no data to be loaded..");
  //     }
  //   } catch (error) {
  //     setJeboEventList(null);
  //     console.log(error);
  //   } finally {
  //   }
  // };

  const toggleLike = async (item) => {
    if (!isLoggedIn) {
      setSignLayer("signIn");
      return;
    }

    try {
      const { status } = await jeboApi.toggleSocialLike(item.scId, !item.like);

      if (status === 200) {
        // console.log(">>>>>>>>>> HAHAHAH");
      }
    } catch (error) {
      console.log(error);
      if (error.response) {
        console.log(error.response);
        if (error.response.status === 403) {
          alert("세션이 만료되었습니다. 다시 로그인해주세요.");
          handleSignOutApp();
        }
      }
    }
  };

  const getJeboEventList = async () => {
    try {
      const {
        status,
        data: { resultData },
      } = await jeboApi.summaryRankedList(0);

      if (status === 200) {
        const jeboEventList = JSON.parse(resultData);
        console.log(jeboEventList, "<<<<<<<<<<<<<<<<<<<<");

        setJeboEventList(jeboEventList);
      } else if (status === 204) {
        setJeboEventList(null);
        console.log("no data to be loaded..");
      }
    } catch (error) {
      setJeboEventList(null);
      console.log(error);
    } finally {
    }
  };

  const getTopJeboMemberList = async () => {
    try {
      const { status, data } = await jeboApi.topJeboMemberList();

      if (status === 200) {
        const rankerArray = JSON.parse(data.rankers);

        setRankers(rankerArray);
      } else if (status === 204) {
        setRankers(null);
        console.log("no data to be loaded..");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <section className="section-contents pc">
      <div className="container">
        <div className="content-wrap">
          {/* <div className="content-container cont-01 default">
            <strong>캠페인&#38;테마</strong>
            <div className="box">
              {
                <Swiper
                  autoplay={true}
                  className="swiper-container live-swiper"
                  spaceBetween={30}
                  slidesPerView={1}
                  loop={
                    jeboEventList && jeboEventList.length > 1 ? true : false
                  }
                  navigation={{
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                  }}
                  pagination={{ clickable: true }}
                  //scrollbar={{ draggable: true }}
                  onSwiper={(swiper) => {}}
                  onSlideChange={() => {}}
                >
                  {jeboEventList ? (
                    jeboEventList.map((event, key) => (
                      <SwiperSlide key={key} className="swiper-slide live-item">
                        <div className="campaign-container">
                          <div className="campaign-btnContainer">
                            <button
                              className="joincamp-btn"
                              onClick={(e) => {
                                e.preventDefault();
                                history.push({
                                  pathname: `${match.url}/add`,
                                  search: `?eventId=${event.eventId}`,
                                });
                              }}
                            >
                              제보하기
                            </button>
                            {event.eventViewCode === "CD03" ||
                            event.eventViewCode === "CD05" ? (
                              <button
                                className="moreView-btn"
                                onClick={async (e) => {
                                  const { status, data } =
                                    await jeboApi.aroundMapFeedList(
                                      0,
                                      0,
                                      0,
                                      0,
                                      "",
                                      `${event.eventId}`,
                                      "0",
                                      "5",
                                      "0"
                                    );

                                  if (status === 200) {
                                    const jeboPostArr = JSON.parse(
                                      data.jeboPost
                                    ).jeboPostList;
                                    const latitude =
                                      jeboPostArr.length > 0
                                        ? jeboPostArr[0].latitude
                                        : "";
                                    const longitude =
                                      jeboPostArr.length > 0
                                        ? jeboPostArr[0].longitude
                                        : "";

                                    history.push({
                                      pathname: `/around`,
                                      search: `?eventId=${
                                        event.eventId
                                      }&latitude=${
                                        latitude ? latitude : ""
                                      }&longitude=${
                                        longitude ? longitude : ""
                                      }`,
                                    });
                                  } else {
                                    history.push({
                                      pathname: `/around`,
                                      search: `?eventId=${event.eventId}`,
                                    });
                                  }
                                }}
                              >
                                지도보기
                              </button>
                            ) : (
                              ""
                            )}
                            {event.eventViewCode === "CD04" ||
                            event.eventViewCode === "CD05" ? (
                              <button
                                className="moreView-btn"
                                onClick={(e) => {
                                  history.push({
                                    pathname: `/search`,
                                    search: `?eventId=${event.eventId}`,
                                  });
                                }}
                              >
                                제보현황
                              </button>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <img
                          className="bannerSectionImg"
                          src={
                            process.env.REACT_APP_RESOURCE_HOST +
                            "/images/event/" +
                            event.webImageName
                          }
                          alt="제보이벤트"
                        />
                      </SwiperSlide>
                    ))
                  ) : (
                    <SwiperSlide className="swiper-slide live-item">
                      <img src={default_event} alt="제보이벤트" />
                    </SwiperSlide>
                  )}
                </Swiper>
              }
            </div>
          </div> */}

          <div className="content-container cont-01 default">
            <strong
              onClick={(e) => {
                e.preventDefault();
                history.push({
                  pathname: `/social`,
                });
              }}
              style={{ cursor: "pointer" }}
            >
              제보큐브
            </strong>
            <div className="box">
              {jeboEventList &&
                jeboEventList.map((data, idx) => (
                  <SocialSection>
                    <ContentItemDefault
                      item={data}
                      category={
                        data.scType === 1
                          ? {
                              name: "theme",
                              title: "테마",
                              type: 1,
                              label: null,
                              creatable: false,
                              joinable: false,
                              postable: true,
                            }
                          : data.scType === 2
                          ? {
                              name: "campaign",
                              title: "캠페인",
                              type: 2,
                              label: null,
                              creatable: false,
                              joinable: false,
                              postable: true,
                            }
                          : data.scType === 3
                          ? {
                              name: "channel",
                              title: "채널",
                              type: 3,
                              label: null,
                              creatable: true,
                              joinable: true,
                              postable: false,
                            }
                          : {
                              name: "community",
                              title: "커뮤니티",
                              type: 4,
                              label: null,
                              creatable: true,
                              joinable: true,
                              postable: true,
                            }
                      }
                      toggleLike={toggleLike}
                      index={idx}
                      section={"home"}
                    />
                  </SocialSection>
                ))}
            </div>
          </div>

          <div className="content-container cont-02 pc">
            <strong className="pc">주변 제보</strong>
            <div className="cont02-slide on">
              <div className="box">
                <BannerMapContainer />
              </div>
            </div>
          </div>
          <div className="content-container cont-03 pc">
            <strong>제보 TOP5 멤버</strong>
            <p className="jeboRank__desc">
              제보에서 가장 활발한 활동을 보이고 있는 멤버!
              <br />
              TOP5 길만 걸으세요!
            </p>
            <div className="box jeboRank_box">
              <div className="user-rank">
                {/* <div className="top">
                  <strong>Ranker</strong>
                  <span>
                    여러분의 제보 덕분에 우리 세상이 이렇게나 좋아졌어요.
                  </span>
                </div> */}
                <div className="bottom">
                  <ul>
                    {rankers.map((ranker, key) => (
                      <li key={key}>
                        <div className="user-item flex">
                          <div className="thumb">
                            <img
                              src={
                                ranker.imageUrl
                                  ? process.env.REACT_APP_RESOURCE_HOST +
                                    ranker.imageUrl
                                  : user_icon
                              }
                              alt="사용자"
                            />
                          </div>
                          <div className="info">
                            <span className="user-name">
                              <Link
                                to={
                                  myInfo &&
                                  myInfo.memberUid === ranker.memberUid
                                    ? { pathname: "/mypage" }
                                    : {
                                        pathname: `/member/${encodeURIComponent(
                                          ranker.nickName
                                        )}`,
                                      }
                                }
                              >
                                {ranker.nickName}
                              </Link>
                            </span>
                          </div>
                          <div className="rankerFeed_count_">
                            <h2 className="rankerFeed_countText">
                              {/* +{ranker.overAge} */}
                            </h2>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="jeboRank_bottom__desc">
                <span>※</span>
                <p>
                  제보 TOP5 멤버는 최근 7일간의 글쓰기 내역을 합산해 실시간으로
                  업데이트 됩니다.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

const SocialSection = styled.div`
  margin-bottom: 10px;

  border: 1px solid transparent;
`;
